import React from 'react';
import ReactDOM from 'react-dom';
import { Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import 'antd/dist/antd.css';
import './index.scss';
import './config';

import Routes from './routes';

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Layout>
      <BrowserRouter createBrowserHistory={history}>
        <Routes />
      </BrowserRouter>
    </Layout>
  </React.StrictMode>,
  document.getElementById('root'),
);
