export default function (total, setPageSize, pageSize, currentPage) {
  return {
    total: total,
    showTotal: (total) => `Total de ${total} Vendas`,
    onShowSizeChange: (_, size) => {
      setPageSize(size);
    },
    pageSizeOptions: ['10', '100', '200', '1000', '10000'],
    pageSize: pageSize,
    current: currentPage,
  };
}
