export const TOKEN_KEY = 'jwt-token';
export const IS_ADMIN = 'is-admin';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token, user) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => localStorage.removeItem(TOKEN_KEY);
export const setIsAdmin = (value) => localStorage.setItem(IS_ADMIN, value);
export const isAdmin = () => localStorage.getItem(IS_ADMIN) === 'true';
export const getLoggedUser = () => JSON.parse(localStorage.getItem('user'));