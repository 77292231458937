import React, { useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import { formatToBRLCurrency } from '../../../utils/numbers';
import {
  formatCNPJ,
  formatCPF,
  formatPercentage,
  removeMask,
} from '../../../utils/format';

const PDFRenderer = ({ sales }) => {
  const {
    broker_owner: broker,
    commission_net_value,
    developer_owner: developer,
    document_client,
    installment,
    intallment_owner_all: installments,
    name_client,
    paid_commission,
    product_owner: product,
    indicator_owner: indicator,
    sales_price,
    signature_date,
    tax,
    observation,
  } = sales;

  const comissionPaid = Number((paid_commission * 100) / sales_price)?.toFixed(
    2
  );
  const netComission = Number(
    Number(comissionPaid) - (Number(comissionPaid) * tax) / 100
  )?.toFixed(2);

  const [financeResume, setFinanceResume] = useState([]);

  useEffect(() => {
    if (installments.length > 0) {
      setFinanceResume(() => {
        const summaryValues = {
          broker: { grossAmount: 0, value: 0, percentage: 0 },
          indicator: { grossAmount: 0, value: 0, percentage: 0 },
          gerente: { grossAmount: 0, value: 0, percentage: 0 },
          aocubo: { grossAmount: 0, value: 0, percentage: 0 },
        };

        installments.forEach((installment) => {
          switch (installment.payee) {
            case 'broker':
              if (installment.value) {
                summaryValues.broker.grossAmount +=
                  installment.payment_type === 'billofsale'
                    ? installment.value + (installment.value * tax) / 100
                    : installment.value;
                summaryValues.broker.value += installment.value;
                summaryValues.broker.percentage += installment.percentage;
              }
              break;
            case 'indicator':
              if (installment.value) {
                summaryValues.indicator.grossAmount +=
                  installment.payment_type === 'billofsale'
                    ? installment.value + (installment.value * tax) / 100
                    : installment.value;
                summaryValues.indicator.value += installment.value;
                summaryValues.indicator.percentage += installment.percentage;
              }
              break;
            case 'gerente':
              if (installment.value) {
                summaryValues.gerente.grossAmount +=
                  installment.payment_type === 'billofsale'
                    ? installment.value + (installment.value * tax) / 100
                    : installment.value;
                summaryValues.gerente.value += installment.value;
                summaryValues.gerente.percentage += installment.percentage;
              }
              break;
            case 'aocubo':
              if (installment.value) {
                summaryValues.aocubo.grossAmount +=
                  installment.payment_type === 'billofsale'
                    ? installment.value + (installment.value * tax) / 100
                    : installment.value;
                summaryValues.aocubo.value += installment.value;
                summaryValues.aocubo.percentage += installment.percentage;
              }
              break;

            default:
              break;
          }
        });
        const payees = [
          ...new Set(installments.map((installment) => installment.payee)),
        ];
        const array = Object.entries(summaryValues).filter((summary) =>
          payees.includes(summary[0])
        );

        return array;
      });
    }
  }, [installments]);

  const payees = {
    broker: 'Corretor',
    gerente: 'Gerente',
    indicator: 'Indicador',
    aocubo: 'AoCubo',
  };

  const styles = StyleSheet.create({
    page: {
      width: '80%',
      padding: '0px',
      margin: '0px',
    },
    logo: {
      height: '40px',
      objectFit: 'scale-down',
      marginTop: 10,
      marginBottom: 20,
    },
    title: {
      fontSize: '16px',
      textAlign: 'center',
      fontWeight: 'bold',
      borderBottom: '1px solid #353232',
      padding: '5px',
      marginBottom: '20px',
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      marginBottom: '5px',
    },
    value: {
      fontSize: 12,
      color: '#353232',
    },
    block: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignItems: 'center',
      margin: '5px',
      padding: '5px',
      borderRadius: '10px',
    },
    alignTableRow: {
      width: '100px',
    },
    spaceBetween: {
      justifyContent: 'space-between',
      padding: '0 35px',
    },
    box: {},

    label: {
      fontWeight: 'bold',
      fontSize: 12,
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: '12px',
      margin: 0,
    },
    tableCellCol: {
      // width: '15%',
      flex: 1,
      border: '1px solid #000000',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '1px',
      padding: '2px',
      color: '#000000',
      textAlign: 'center',
    },
    tableCellRow: {
      // width: '15%',
      flex: 1,
      border: '1px solid #353232',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      color: '#353232',
      textAlign: 'center',
    },
  });

  const payee = {
    broker: 'Corretor',
    indicator: 'Indicador',
    aocubo: 'AoCubo',
    gerente: 'Gerente',
  };

  const payment_type = {
    check: 'Cheque',
    billofsale: 'Nota',
    ted: 'Ted',
    pay_office: 'Pagadoria'

  };

  const installment_status = {
    receivable: 'Á receber',
    received: 'Recebido',
  };

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: '20px',
              fontWeight: 'black',
              marginRight: 10,
            }}
          >
            Demonstrativo de Pagamento
          </Text>
          <Image src="/logo3x.png" style={styles.logo} />
        </View>

        <Text style={styles.title}>Dados da Composição da Venda</Text>
        <View style={styles.block} wrap>
          <View style={styles.box}>
            <View style={styles.item}>
              <Text style={styles.label}>Corretor: </Text>
              <Text style={styles.value}>{broker.first_name}</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.label}>Gerente: </Text>
              <Text style={styles.value}>{broker.first_name}</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.label}>Indicador: </Text>
              <Text style={styles.value}>
                {indicator ? indicator.first_name : 'N/A'}
              </Text>
            </View>
          </View>
          <View style={styles.box}>
            <View style={styles.item}>
              <Text style={styles.label}>Incorporadora: </Text>
              <Text style={styles.value}>{developer.name}</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.label}>Produto: </Text>
              <Text style={styles.value}>{product.name}</Text>
            </View>
          </View>

          <View style={styles.box}>
            <View style={styles.item}>
              <Text style={styles.label}>Cliente: </Text>
              <Text style={styles.value}>{name_client}</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.label}>
                {removeMask(document_client).length <= 11 ? 'CPF: ' : 'CNPJ: '}
              </Text>
              <Text style={styles.value}>
                {removeMask(document_client).length <= 11
                  ? formatCPF(document_client)
                  : formatCNPJ(document_client)}
              </Text>
            </View>
          </View>
        </View>

        <Text style={styles.title}>Dados da Venda</Text>
        <View style={styles.block} wrap>
          <View style={styles.box}>
            <View style={styles.item}>
              <Text style={styles.label}>Valor de Venda: </Text>
              <Text style={styles.value}>
                {formatToBRLCurrency(sales_price)}
              </Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.label}>Data Assinatura: </Text>
              <Text style={styles.value}>
                {moment(signature_date).format('DD/MM/YYYY')}
              </Text>
            </View>
          </View>

          <View style={styles.box}>
            <View style={styles.item}>
              <Text style={styles.label}>Comissão Paga: </Text>
              <Text
                style={{
                  ...styles.value,
                  marginLeft: 11,
                }}
              >
                {comissionPaid}%
              </Text>
            </View>

            <View style={styles.item}>
              <Text style={styles.label}>Comissão Líquida: </Text>
              <Text style={styles.value}>
                {commission_net_value && Number(netComission)}%
              </Text>
            </View>
          </View>

          <View style={styles.box}>
            <View style={styles.item}>
              <Text style={styles.label}>Desconto: </Text>
              <Text style={styles.value}>
                {`${tax.toString().replace('.', ',')}%`}
              </Text>
            </View>

            <View style={styles.item}>
              <Text style={styles.label}>Parcelas: </Text>
              <Text style={styles.value}>{installment}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.title}>Resumo do Pagamento</Text>
        <View style={{ ...styles.block }}>
          <Text style={{ ...styles.label, ...styles.alignTableRow }}>
            Beneficiario
          </Text>
          <Text style={{ ...styles.label, ...styles.alignTableRow }}>
            Percentual
          </Text>
          <Text style={{ ...styles.label, ...styles.alignTableRow }}>
            Comissão Bruta
          </Text>
          <Text style={{ ...styles.label, ...styles.alignTableRow }}>
            Comissão Liquida
          </Text>
        </View>
        {financeResume.map((finance, index) => (
          <View style={{ ...styles.block }} key={index} wrap>
            <Text style={{ ...styles.label, ...styles.alignTableRow }}>
              {payees[finance[0]]}
            </Text>
            <Text style={{ ...styles.label, ...styles.alignTableRow }}>
              {formatPercentage(finance?.[1].percentage)}
            </Text>
            <Text style={{ ...styles.label, ...styles.alignTableRow }}>
              {formatToBRLCurrency(finance?.[1].grossAmount)}
            </Text>
            <Text
              style={{
                ...styles.label,
                ...styles.alignTableRow,
              }}
            >
              {formatToBRLCurrency(finance?.[1].value)}
            </Text>
          </View>
        ))}

        <Text style={styles.title}>Dados do Pagamento</Text>
        <View
          style={{
            ...styles.tableRow,
            margin: '0 30px',
          }}
          wrap
        >
          <Text style={styles.tableCellCol}>Parcelas</Text>
          <Text style={styles.tableCellCol}>Beneficiário</Text>
          <Text style={styles.tableCellCol}>Recebimento</Text>
          {/* <Text style={styles.tableCell}>Porcentagem</Text> */}
          <Text style={styles.tableCellCol}>Valor</Text>
        </View>

        {installments.map((installment, index) => (
          <View
            style={{
              ...styles.tableRow,
              margin: '0 30px',
            }}
            wrap
            key={index}
          >
            <Text style={styles.tableCellRow}>
              {`${installment.plots}° parcela`}
            </Text>
            <Text style={styles.tableCellRow}>{payee[installment.payee]}</Text>
            <Text style={styles.tableCellRow}>
              {moment(installment.receivement_date).format('DD/MM/YYYY')}
            </Text>
            {/* <Text style={styles.tableCellRow}>
              {installment.percentage.toFixed(2).toString().replace('.', ',')}
            </Text> */}
            <Text style={styles.tableCellRow}>
              {formatToBRLCurrency(installment.value)}
            </Text>
          </View>
        ))}

        <View
          style={{
            margin: '10px 30px',
          }}
          wrap
        >
          <View style={styles.box}>
            <Text
              style={{
                fontSize: 11,
                color: 'gray',
              }}
            >
              Obs: {observation}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFRenderer;
