import React from 'react';
import { message } from 'antd';

import Fields from '../../components/pages/products/fields';

import { getToken } from '../../services/auth';
import { requestAPI } from '../../config';

const Register = (props) => {

  function handleSubmit(values) {
    fetch(requestAPI(`/product`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${getToken()}`,
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.error) {
          message.error(resp.error);
        } else {
          message.success('Cadastrado com sucesso!');
          props.history.push('/produtos');
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  return <Fields handleSubmit={handleSubmit} />;
};

export default Register;
