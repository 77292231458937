import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import { requestAPI } from '../../config';

const DevelopersDropdown = ({ setValue, value }) => {
  const [options, setOptions] = useState();
  const [inputText, setInputText] = useState(value);

  useEffect(() => {
    const queryString =
      typeof inputText === 'number' ? `/${inputText}` : `?name=${inputText}`;

    fetch(requestAPI(`/developers${inputText ? queryString : ''}`))
      .then((resp) => resp.json())
      .then((resp) => {
        const developersOptions =
          resp.developers &&
          resp.developers.map((developer) => ({
            value: developer.id,
            label: developer.name,
          }));

        if (!developersOptions) setInputText(resp.name);

        setOptions(developersOptions);
      });
  }, [inputText, value]);

  if (options && typeof inputText === 'undefined') {
    const defaultOptions = options.filter((option) => option.value === value);
    if (defaultOptions && defaultOptions.length > 0) {
      setInputText(defaultOptions[0].label);
    }
  }

  return (
    <AutoComplete
      allowClear
      placeholder="Incorporadora"
      options={options}
      onChange={(value) => {
        setInputText(value || '');
        if (!value) setValue({ developer: undefined });
      }}
      value={inputText}
      onSelect={(value, option) => {
        setValue && setValue({ developer: value });
        setInputText(option.label);
      }}
    />
  );
};

export default DevelopersDropdown;
