import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Card, Table } from 'antd';

import { getToken } from '../../services/auth';
import { requestAPI } from '../../config';

function Users() {
  const [users, setUsers] = useState({ users: [] });

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Nome', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Admin',
      dataIndex: 'profile',
      key: 'profile',
      render: (_, record) => (record.profile ? 'Administrador' : 'Usuário'),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (texto, record) => (
        <span>
          <Link
            to={`/usuarios/editar/${record.id}`}
            style={{ marginRight: 16 }}
          >
            Visualizar
          </Link>
        </span>
      ),
    },
  ];

  useEffect(() => {
    fetch(requestAPI(`/users`), {
      method: 'GET',
      headers: { Authorization: `bearer ${getToken()}` },
    })
      .then((resp) => resp.json())
      .then(setUsers);
  }, []);

  return (
    <Card
      title="Produtos"
      extra={
        <Link className="ant-btn" to="/usuarios/cadastrar">
          Cadastrar
        </Link>
      }
    >
      <Table columns={columns} dataSource={users.users} />
    </Card>
  );
}

export default Users;
