import React from 'react';
import { Card, Row, Col, Input, Button, Form, Popconfirm, Select } from 'antd';
import { isAdmin } from '../../../services/auth';

export default ({ user, handleSubmit, handleDelete }) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  return (
    <Form onFinish={handleSubmit} initialValues={user} form={form}>
      <Card title="Cadastro de Usuário">
        <Row>
          <Col span={7}>
            <Item label="Nome" name="name" rules={[{ required: true }]}>
              <Input placeholder="nome" />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item label="Email" name="email" rules={[{ required: true }]}>
              <Input placeholder="email" />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Item label="Senha" name="password">
              <Input.Password placeholder="senha" />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item label="Perfil" name="profile" rules={[{ required: true }]}>
              <Select placeholder="Perfil">
                <Select.Option value={true}>Administrador</Select.Option>
                <Select.Option value={false}>Usuário</Select.Option>
              </Select>
            </Item>
          </Col>
        </Row>

        <div className="call-to-action">
          <Button className="save" type="primary" htmlType="submit">
            Salvar
          </Button>

          {handleDelete && isAdmin() && (
            <Popconfirm
              title="Você tem certeza que deseja excluir essa incorporadora?"
              onConfirm={handleDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button className="delete" type="danger">
                Excluir
              </Button>
            </Popconfirm>
          )}
        </div>
      </Card>
    </Form>
  );
};
