import React, { useState, useEffect, useRef } from 'react';
import {
  DatePicker,
  Select,
  Button,
  Card,
  Form,
  InputNumber,
  Input,
} from 'antd';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import { normalizeNumber, formatToBRLCurrency } from '../../../utils/numbers';

function Payment({ installment, setFields, form, defaultValues }) {
  const [lines, setLines] = useState(
    (defaultValues && defaultValues.length) || 1, 
  );
  const [values, setValues] = useState(defaultValues || []);
  const [summary, setSummary] = useState({
    broker: { grossAmount: 0, value: 0, percentage: 0 },
    indicator: { grossAmount: 0, value: 0, percentage: 0 },
    gerente: { grossAmount: 0, value: 0, percentage: 0 },
    aocubo: { grossAmount: 0, value: 0, percentage: 0 },
  });

  const { Item } = Form;



  useEffect(() => {
    setFields({ sales_installment: values });
  }, [form, setFields, values]);

  useEffect(() => {
    const summaryValues = {
      broker: { grossAmount: 0, value: 0, percentage: 0 },
      indicator: { grossAmount: 0, value: 0, percentage: 0 },
      gerente: { grossAmount: 0, value: 0, percentage: 0 },
      aocubo: { grossAmount: 0, value: 0, percentage: 0 },
    };

    values.forEach((value) => {
      switch (value.payee) {
        case "broker":
          if (value.value) {
            summaryValues.broker.grossAmount += value.value;
            summaryValues.broker.value += value.value;
            summaryValues.broker.percentage += value.percentage;
          }
          break;
        case "indicator":
          if (value.value) {
            summaryValues.indicator.grossAmount += value.value;
            summaryValues.indicator.value += value.value;
            summaryValues.indicator.percentage += value.percentage;
          }
          break;
        case "gerente":
          if (value.value) {
            summaryValues.gerente.grossAmount += value.value;
            summaryValues.gerente.value += value.value;
            summaryValues.gerente.percentage += value.percentage;
          }
          break;
        case "aocubo":
          if (value.value) {
            summaryValues.aocubo.grossAmount += value.value;
            summaryValues.aocubo.value += value.value;
            summaryValues.aocubo.percentage += value.percentage;
          }
          break;

        default:
          break;
      }
    });

    setSummary(summaryValues);
  }, [values, form]);

  return (
    <Card
      className="payment"
      title="Pagamentos"
      extra={
        <Button onClick={() => setLines(lines + 1)}>Adicionar linha</Button>
      }
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Parcelas</th>
            <th>Perfil</th>
            <th>Recebimento</th>
            <th>Pagamento</th>
            <th>Porcentagem</th>
            <th>Valor</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: lines }).map((line, index) => {
            return (
              <Line
                form={form}
                index={index}
                installment={installment}
                key={line}
                setValues={setValues}
                values={values}
              />
            );
          })}
        </tbody>
      </table>
      <div className="sales-summary">
        <div className="description-container">
          <div className="description">
            <h1 className="description-title">Observações:</h1>
            <Item
              name='observation'
            >
              <Input.TextArea
                rows={1}
                placeholder="Deixe uma observação aqui:"
              />
            </Item>
          </div>
        </div>
      </div>
    </Card>
  );
}

function Line({ installment, setValues, values, index, form }) {
  /* WARNING: Esse componente deve apenas atualizar o estado de value de um modo muito especifico, quem desenvolveu isso no passado condena a gente hoje.
     values[index] = {
              ...values[index],
              value: installmentValue,
              grossAmount: commission,
      };

      setValues(values)

      Assim o componente não é rerenderizado(O que é outro débito...)

      Para atualizar informações caso o valor de um componente tenha dependencia de outro desse componente o único modo que achei, foi usando ref, assim o valor é atualizado
  */

  const ref = useRef(null)
  const stringSalesPrice = Form.useWatch('sales_price', form)

  const salesPrice = normalizeNumber(stringSalesPrice)

  const { Option } = Select;
  const { Item } = Form;

  function getInstallmentOption() {
    let options = [];

    for (let index = 1; index <= installment; index++) {
      options.push(<Option value={index}>Parcela {index}</Option>);
    }

    return options;
  }

  return (
    <tr>
      <Item name="sales_installment" className="hidden" />
      <td>
        <Select
          className="item"
          defaultValue={values[index] && values[index].plots}
          placeholder="Parcela"
          onSelect={(value) => {
            values[index] = { ...values[index], plots: value };
            setValues(values);
          }}
        >
          {getInstallmentOption()}
        </Select>
      </td>
      <td>
        <Select
          className="item"
          defaultValue={values[index] && values[index].payee}
          placeholder="Perfil"
          onSelect={(value) => {
            values[index] = { ...values[index], payee: value };
            setValues(values);
          }}
        >
          <Option value="broker">Corretor</Option>
          <Option value="indicator">Indicador</Option>
          <Option value="gerente">Gerente</Option>
          <Option value="aocubo">AoCubo</Option>
        </Select>
      </td>
      <td>
        <DatePicker
          className="item"
          defaultValue={values[index] && moment(values[index].receivement_date)}
          placeholder="Data"
          format="DD/MM/YYYY"
          onSelect={(value) => {
            values[index] = { ...values[index], receivement_date: value };
            setValues(values);
          }}
        />
      </td>
      <td>
        <Select
          className="item"
          defaultValue={values[index] && values[index].payment_type}
          placeholder="Tipo de pagamento"
          onSelect={(value) => {
            values[index] = { ...values[index], payment_type: value };
            setValues([...values]);
          }}
        >
          <Option value="check">Cheque</Option>
          <Option value="billofsale">Nota</Option>
          <Option value="ted">Ted</Option>
          <Option value="pay_office">Pagadoria</Option>
        </Select>
      </td>
      <td>
        <InputNumber
          className="item"
          decimalSeparator=","
          ref={ref}
          defaultValue={values[index] && values[index].percentage?.toFixed(2)}
          name="percentage"
          placeholder="Porcentagem"
          disabled
          onChange={(percentageValue) => {
            values[index] = { ...values[index], percentage: percentageValue };
            setValues(values);

            
            const commission = (salesPrice * percentageValue) / 100;

            const installmentValue = commission

            values[index] = {
              ...values[index],
              value: installmentValue,
              grossAmount: commission,
            };

            setValues([...values]);
          
          }}
          value={values[index]?.percentage ?? 0}
        />
      </td>
      <td>
        <NumberFormat
          customInput={(props) => <Input {...props}  defaultValue={values[index] && values[index].value}/>}
          className="item"
          defaultValue={values[index] && values[index].value}
          decimalScale={2}
          decimalSeparator=","
          thousandSeparator="."
          prefix={'R$ '}
          min={0}
          onValueChange={({floatValue}) => {
            if (!floatValue) {
              return
            }

            const percentage = (floatValue * 100) / salesPrice;

            
            values[index] = {
              ...values[index],
              percentage,
              value: floatValue,
              grossAmount: floatValue
            };

            if (!percentage) {
              ref.current.value = 0
            }
            ref.current.value = (percentage?.toFixed(2) ?? 0)

            setValues(values);
          }}
          placeholder="Valor"
        />
      </td>
      <td>
        <Select
          className="item"
          name="status"
          defaultValue={values[index] && values[index].status}
          placeholder="status"
          onSelect={(value) => {


            values[index] = { ...values[index], status: value };

            setValues([...values]);
          }}
        >
          <Option value="receivable">Á receber</Option>
          <Option value="received">Recebido</Option>
        </Select>
      </td>
    </tr>
  );
}

Payment.propTypes = {
  installment: PropTypes.arrayOf(PropTypes.number),
  setFields: PropTypes.string,
  form: PropTypes.any,
  salesPrice: PropTypes.number,
  defaultValues: PropTypes.arrayOf(
    PropTypes.exact({
      createdAt: PropTypes.string,
      id: PropTypes.number,
      payee: PropTypes.string,
      payment_type: PropTypes.string,
      percentage: PropTypes.number,
      plots: PropTypes.number,
      receivement_date: PropTypes.string,
      sales_id: PropTypes.number,
      status: PropTypes.string,
      updatedAt: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

export default Payment;
