import { getToken } from './services/auth';

export const config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
};

export function requestAPI(path) {
  return new Request(config.apiEndpoint + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}