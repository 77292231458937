import React, { useState, useEffect } from 'react';
import { message, Row, Col } from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { getToken } from '../../services/auth';

import Fields from '../../components/pages/sales/fields';
import PDFDoc from '../../components/pages/sales/pdf-renderer';
import { normalizeNumber } from '../../utils/numbers';
import { requestAPI } from '../../config';

const Sales = ({ match, history }) => {
  const [sales, setSales] = useState();
  const [intallmentOwnerAll, setIntallmentOwnerAll] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState();
  const { id } = match.params;

  const saleFullInfo = sales && {
    ...sales.sales,
    ...sales.sales.product_characteristics,
  };


  useEffect(() => {
    fetch(requestAPI(`/sales/${id}`), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.error) {
          message.error(json.error);
        } else {
          setSales(json);
          setIntallmentOwnerAll(json.sales.intallment_owner_all);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, [id]);

  function handleSubmit(values) {
    setLoadingRequest(true);
    values.paid_commission = normalizeNumber(values.paid_commission);
    values.commission_net_value = normalizeNumber(values.commission_net_value);
    values.sales_price = normalizeNumber(values.sales_price);

    fetch(requestAPI(`/sales/${id}`), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${getToken()}`,
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setLoadingRequest(false);
        if (resp.error) {
          message.error(resp.error);
        } else {
          message.success('Alterações salvas com sucesso!');
          history.push('/vendas');
        }
      })
      .catch((err) => {
        setLoadingRequest(false);
        message.error(err.message);
      });
  }

  function handleDelete() {
    fetch(requestAPI(`/sales/${id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `bearer ${getToken()}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.error) {
          message.error(resp.error);
        } else {
          message.success('Excluido com sucesso!');
          history.push('/vendas');
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  function filterInstallment(filter) {
    return {
      intallment_owner_all: saleFullInfo.intallment_owner_all.filter(
        (installment) => installment.payee === filter
      ),
    };
  }

  return sales ? (
    <>
      <Fields
        handleDelete={handleDelete}
        handleSubmit={handleSubmit}
        sales={saleFullInfo}
        loadingRequest={loadingRequest}
      />
    </>
  ) : null;
};

export default Sales;
