import React from 'react';
import { message } from 'antd';

import Fields from '../../components/pages/brokers/fields';

import { requestAPI } from '../../config';
import { getToken } from '../../services/auth';

const BrokerRegister = (props) => {
  function handleSubmit(values) {
    fetch(requestAPI(`/broker`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${getToken()}`,
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        message.success('Cadastrado com sucesso!');
        props.history.push('/corretor');
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  return <Fields handleSubmit={handleSubmit} />;
};

export default BrokerRegister;
