/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  DatePicker,
  Button,
  Form,
  Radio,
  Row,
  Col,
  Card,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  message,
} from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import Developers from '../../Dropdowns/developers';
import Brokers from '../../Dropdowns/brokers';
import Product from '../../Dropdowns/products';
import Payment from '../../pages/sales/payment';
import { isAdmin } from '../../../services/auth';
import { normalizeNumber, formatToBRLCurrency } from '../../../utils/numbers';

import { getToken } from '../../../services/auth';
import { requestAPI } from '../../../config';

const SaleFields = ({ sales, handleSubmit, handleDelete, loadingRequest }) => {
  const [installment, setInstallment] = useState(
    (sales && sales.installment) || 0
  );
  const gambi = useRef(null)
  const [dealId, setDealId] = useState('');
  //const [dealInfos, setDealInfos] = useState({});
  const [developerId, setDeveloperId] = useState(0);

  const { Item } = Form;
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState();

  const getNetComission = () => {
    const value = form.getFieldValue('tax');
    const paidCommission = normalizeNumber(
      form.getFieldValue('paid_commission')
    );
    const commissionNetValue = formatToBRLCurrency(
      paidCommission - (paidCommission * value) / 100
    );
    form.setFieldsValue({
      commission_net_value: commissionNetValue,
    });
  };
  const dealRequest = () => {
    fetch(requestAPI(`/sales/deals/${dealId}`), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.error) {
          message.error(json.error);
        } else {
          form.setFieldsValue({
            gerente_ibroker: json.deal.financeManager.value,
            email_client: json.contact.email,
            name_client: `${json.contact.firstname} ${json.contact.lastname}`,
            cellphone_client: json.contact.phone,
            contact_id: json.contact.id,
            document_client: json.deal.financeCPF.value,
            unit: json.deal.financeUnity.value,
            signature_date: moment(
              json.deal.financeSubscribeDate.value,
              'MM-DD-YYYY'
            ),
            sales_price: json.deal.financeVGV.value,
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  if (sales) {
    sales.signature_date = moment(sales.signature_date, 'YYYY-MM-DD');
  }

  

  const initialValues = {
    parking_slot: 0,
    bedrooms: 0,
    bathrooms: 0,
    living_area: 0,
    suites: 0,
    //email_client: dealInfos.contact.email || ''
  };

  useEffect(() => {
    getNetComission();
  }, [developerId]);

  return (
    <Form
      onFinish={handleSubmit}
      initialValues={{ ...initialValues, ...sales }}
      form={form}
    >
      <Card>
        <Card title='Cadastro da venda' className='sale-card'>
          <Row className='sale-fields'>
            <Col span={24} className='column-items'>
              <Item
                label='ID da negociação'
                name='deal_id'
                className='item-container deal-id'
              >
                <Input
                  className='negotiation-id'
                  placeholder='ID da negociação'
                  onChange={(e) => setDealId(e.target.value)}
                />
              </Item>
              <Button className='request-deal' onClick={dealRequest}>
                <svg
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 17 17'
                >
                  <path
                    d='m15.2 16.6-5.72-5.7a6 6 0 1 1 1.41-1.42l5.72 5.71-1.42 1.42ZM6 2a4 4 0 1 0 2.92 1.26l.6.6-.68-.68h-.01A3.97 3.97 0 0 0 6 2Z'
                    fill='#00E6B8'
                  />
                </svg>
              </Button>
            </Col>
            <Col span={24} className='column-items'>
              <Item
                name='origin'
                label='Origem da Venda'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                className='item-container'
              >
                <Radio.Group
                  onChange={(e) => {
                    setRadioValue(e.target.value);
                  }}
                  value={radioValue}
                >
                  <Space direction='horizontal'>
                    <Radio value='rede'>Rede</Radio>
                    <Radio value='ibroker'>iBroker</Radio>
                    <Radio value='plataforma'>Plataforma</Radio>
                    <Radio value='supply'>Supply</Radio>
                    <Radio value='sdr'>Sdr</Radio>
                  </Space>
                </Radio.Group>
              </Item>
            </Col>
            <Col span={24} className='column-items'>
              <Item
                name='developer'
                label='Incorporadora'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                onClick={() => setDeveloperId(form.getFieldValue("developer"))}
                className='item-container'
              >
                <Developers setValue={form.setFieldsValue} />
              </Item>
            </Col>
            <Col span={24} className='column-items'>
              <Item
                name='product_id'
                label='Produto'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                className='item-container'
              >
                <Product
                  setValue={form.setFieldsValue}
                  developerId={developerId}
                />

              </Item>
            </Col>
            <Col span={24} className='column-items'>
              <Item
                name='broker_id_indicator'
                label='Indicador'
                className='item-container'
              >
                <Brokers
                  placeholder='Indicador'
                  fieldName='broker_id_indicator'
                  setValue={form.setFieldsValue}
                />
              </Item>
            </Col>
            <Col span={24} className='column-items'>
              <Item
                name='broker_id'
                label='Corretor'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                className='item-container'
              >
                <Brokers setValue={form.setFieldsValue} />
              </Item>
            </Col>
            <Col span={24} className='column-items'>
              <Item
                name='gerente_ibroker'
                label='Gerente'
                // rules={[{ required: true, message: 'Campo obrigatório' }]}
                className='item-container'
              >
                <Input className='document item' placeholder='Gerente' />
              </Item>
            </Col>
          </Row>
        </Card>

        <Card title='Cadastro do Cliente' className='client-card'>
          <Row className='client-fields'>
            <Col span={24} className='column-items'>
              <Item
                name='contact_id'
                label='ID do Contato'
              >
                <Input
                  className='client item'
                  placeholder='ID do Contato'
                  disabled
                />
              </Item>
            </Col>
            <Col span={24} className='column-items'>
              <Item
                name='name_client'
                label='Cliente'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input className='client item' placeholder='Nome do Cliente' />
              </Item>
            </Col>
            <Col span={24} offset={1} className='column-items'>
              <Item
                name='document_client'
                label='CPF'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input className='document item' placeholder='CPF' />
              </Item>
            </Col>
            <Col span={24} offset={1} className='column-items'>
              <Item
                name='cellphone_client'
                label='Telefone'
              // rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  className='document item'
                  placeholder='Telefone Cliente'
                />
              </Item>
            </Col>
            <Col span={24} offset={1} className='column-items'>
              <Item
                label='Email'
                // rules={[{ required: true, message: 'Campo obrigatório' }]}
                name='email_client'
              >
                <Input
                  className='document item'
                  placeholder='Email Cliente'
                //name="email_client"
                />
              </Item>
            </Col>
          </Row>
        </Card>

        <Card title='Características do produto' style={{ marginBottom: 16 }}>
          <Row>
            <Col span={5}>
              <Item
                name='unit'
                label='Unidade'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input placeholder='Unidade' min={0} />
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item name='parking_slot' label='Garagem'>
                <InputNumber className='item' placeholder='Vagas' min={0} />
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item name='bedrooms' label='Quartos'>
                <InputNumber className='item' placeholder='Quartos' min={0} />
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item name='bathrooms' label='Banheiros'>
                <InputNumber className='item' placeholder='Banheiros' min={0} />
              </Item>
            </Col>
            <Col span={5}>
              <Item name='living_area' label='Área útil'>
                <InputNumber className='item' placeholder='Área útil' min={0} />
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item name='suites' label='Suítes'>
                <InputNumber
                  name='suites'
                  className='item'
                  placeholder='Suites'
                  min={0}
                />
              </Item>
            </Col>
          </Row>
        </Card>
        <Card title='Venda' style={{ marginBottom: 16 }}>
          <Row>
            <Col span={7}>
              <Item
                name='sales_price'
                label='Valor da venda'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <NumberFormat
                  customInput={(props) => <Input {...props} />}
                  defaultValue={sales && formatToBRLCurrency(sales.sales_price)}
                  decimalScale={2}
                  decimalSeparator=','
                  prefix={'R$ '}
                  placeholder='Valor da venda'
                  min={0}
                // thousandSeparator="."
                />
              </Item>
            </Col>
            <Col span={7} offset={1}>
              <Item
                name='paid_commission'
                label='Comissão Paga'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <NumberFormat
                  customInput={(props) => <Input {...props} />}
                  decimalSeparator=','
                  defaultValue={
                    sales && formatToBRLCurrency(sales.paid_commission)
                  }
                  decimalScale={2}
                  prefix={'R$ '}
                // thousandSeparator="."
                />
              </Item>
            </Col>
            <Col span={7} offset={1}>
              <Item
                name='tax'
                label='Alíquota'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <InputNumber
                  className='item'
                  decimalSeparator=','
                  placeholder='Alíquota'
                  min={0}
                  onChange={(value) => {
                    const paidCommission = normalizeNumber(
                      form.getFieldValue('paid_commission')
                    );
                    const commissionNetValue = formatToBRLCurrency(
                      paidCommission - (paidCommission * value) / 100
                    );
                    form.setFieldsValue({
                      commission_net_value: commissionNetValue,
                    });
                  }}
                />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Item
                name='signature_date'
                label='Data Assinatura'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  placeholder='Data Assinatura'
                  format='DD/MM/YYYY'
                  style={{ width: '100%' }}
                />
              </Item>
            </Col>
            <Col span={7} offset={1}>
              <Item
                name='commission_net_value'
                label='Comissão Líquida'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <NumberFormat
                  customInput={(props) => <Input {...props} />}
                  disabled
                  decimalSeparator=','
                  prefix={'R$ '}
                // thousandSeparator="."
                />
              </Item>
            </Col>
            <Col span={7} offset={1}>
              <Item
                name='installment'
                label='Parcelas'
                rules={[{ required: true, message: 'Campo é obrigatório' }]}
              >
                <InputNumber
                  className='item'
                  placeholder='Parcelas'
                  onChange={setInstallment}
                  min={0}
                />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Item
                name='status'
                label='Status da venda'
                rules={[{ required: true, message: 'Campo é obrigatório' }]}
              >
                <Select className='item' placeholder='Status'>
                  <Select.Option value='ok'>ok</Select.Option>
                  <Select.Option value='cancellation'>Distrato</Select.Option>
                  <Select.Option value='spreadsheet_pending'>
                    Planilha pendente
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
        </Card>

        {/* Quadro de comissão de venda */}
        <div>
          <Payment
            installment={installment}
            setFields={form.setFieldsValue}
            form={form}
            defaultValues={sales && sales.intallment_owner_all}
          />
        </div>

        <div className='call-to-action'>
          <Button
            className='save'
            type='primary'
            htmlType='submit'
            loading={loadingRequest}
          >
            Salvar
          </Button>

          {Boolean(isAdmin()) && handleDelete && (
            <Popconfirm
              title='Você tem certeza que deseja excluir essa incorporadora?'
              onConfirm={handleDelete}
              okText='Sim'
              cancelText='Não'
            >
              <Button className='delete' type='danger'>
                Excluir
              </Button>
            </Popconfirm>
          )}
        </div>
      </Card>
    </Form >
  );
};

export default SaleFields;
