import React, { useState } from 'react';
import { Button, Card, Input, Form, message } from 'antd';

import { login, setIsAdmin } from '../../services/auth';
import { requestAPI } from '../../config';

import './styles.scss';

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const { Item } = Form;

  function handleLogin(formValues) {
    const { email, password } = formValues;
    setLoading(true);

    fetch(requestAPI('/sessions'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        if (json.error) {
          if (json.error === ' Validations Fails ') {
            message.error('Usuário ou senha inválido');
          } else if (json.error === 'User not found') {
            message.error('Usuário não encontrado');
          } else {
            message.error(json.error);
          }
        }
        if (json.token) {
          login(json.token, json.user);
          setIsAdmin(json.user.profile)
          props.history.push('/vendas');
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error('Error ao logar');
      });
  }

  return (
    <Form className="login" onFinish={handleLogin}>
      <Card title="Login">
        <Item name="email">
          <Input placeholder="email" type="email" />
        </Item>
        <Item name="password">
          <Input placeholder="senha" type="password" />
        </Item>
        <Button loading={loading} htmlType="submit">
          Login
        </Button>
      </Card>
    </Form>
  );
};

export default Login;
