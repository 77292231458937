import React from 'react';
import {
  Card,
  DatePicker,
  Row,
  Col,
  Input,
  Button,
  Form,
  Popconfirm,
} from 'antd';

import moment from 'moment';

import Address from '../../Address';
import Developers from '../../Dropdowns/developers';
import { isAdmin } from '../../../services/auth';

export default ({ product, handleSubmit, handleDelete }) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  if (product) {
    product.delivery_date = moment(product.delivery_date, 'YYYY-MM-DD');
  }

  return (
    <Form onFinish={handleSubmit} initialValues={product} form={form}>
      <Card title="Cadastro de Produto">
        <Row>
          <Col span={10}>
            <Item label="Nome" name="name" rules={[{ required: true }]}>
              <Input placeholder="Nome do empreendimento" />
            </Item>
          </Col>
          <Col span={10} offset={1}>
            <Item
              label="Data de entrega"
              name="delivery_date"
              rules={[{ required: true }]}
            >
              <DatePicker
                placeholder="Lançamento"
                name="delivery_date"
                format="DD/MM/YYYY"
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Item
              label="Incorporadora"
              name="developer"
              rules={[{ required: true }]}
            >
              <Developers
                setValue={form.setFieldsValue}
                value={product && product.developer}
              />
            </Item>
          </Col>
        </Row>

        <Address form={form} />

        <div className="call-to-action">
          <Button className="save" type="primary" htmlType="submit">
            Salvar
          </Button>

          {handleDelete && isAdmin() && (
            <Popconfirm
              title="Você tem certeza que deseja excluir essa incorporadora?"
              onConfirm={handleDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button className="delete" type="danger">
                Excluir
              </Button>
            </Popconfirm>
          )}
        </div>
      </Card>
    </Form>
  );
};
