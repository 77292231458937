import React from 'react';
import { Card, Row, Col, Input, Button, Form, Popconfirm } from 'antd';

import Address from '../../Address';
import { isAdmin } from '../../../services/auth';

export default ({ developer, handleSubmit, handleDelete }) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={developer}>
      <Card title="Cadastro de Incorporadora">
        <Row>
          <Col span={6}>
            <Item
              label="CNPJ"
              name="document"
              rules={[{ required: true }]}
            >
              <Input placeholder="CNPJ" />
            </Item>
          </Col>
          <Col span={6} offset={1}>
            <Item
              label="Nome"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Nome" />
            </Item>
          </Col>
          <Col span={6} offset={1}>
            <Item
              label="Telefone"
              name="telephone"
              rules={[{ required: true }]}
            >
              <Input placeholder="Telefone" />
            </Item>
          </Col>
        </Row>

        <Address form={form} />

        <div className="call-to-action">
          <Button className="save" type="primary" htmlType="submit">
            Salvar
          </Button>

          {handleDelete && isAdmin() && (
            <Popconfirm
              title="Você tem certeza que deseja excluir essa incorporadora?"
              onConfirm={handleDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button className="delete" type="danger">
                Excluir
              </Button>
            </Popconfirm>
          )}
        </div>
      </Card>
    </Form>
  );
};
