import React from 'react';
import { Card, Row, Col, Input, Form } from 'antd';

export default ({ form }) => {
  const { Item } = Form;

  return (
    <Card title="Endereço">
      <Row>
        <Col span={5}>
          <Item name="cep" label="CEP">
            <Input
              placeholder="CEP"
              onBlur={({ target }) => {
                fetch(`https://viacep.com.br/ws/${target.value}/json`)
                  .then((resp) => resp.json())
                  .then((resp) => {
                    form.setFieldsValue({
                      ...resp,
                      estado: resp.uf,
                      cidade: resp.localidade,
                    });
                  })
                  .catch((err) => console.error(err));
              }}
            />
          </Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item name="logradouro" label="Logradouro">
            <Input placeholder="Logradouro" />
          </Form.Item>
        </Col>
        <Col span={5} offset={1}>
          <Item name="bairro" label="Bairro">
            <Input placeholder="Bairro" />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <Item name="estado" label="Estado">
            <Input placeholder="Estado" />
          </Item>
        </Col>
        <Col span={5} offset={1}>
          <Item name="cidade" label="Cidade">
            <Input placeholder="Cidade" />
          </Item>
        </Col>
        <Col span={5} offset={1}>
          <Item name="numero" label="Número">
            <Input placeholder="Número" />
          </Item>
        </Col>
      </Row>
    </Card>
  );
};
