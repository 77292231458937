import React, { useState } from 'react';
import { message } from 'antd';

import Fields from '../../components/pages/sales/fields';

import { getToken } from '../../services/auth';
import { requestAPI } from '../../config';

import { normalizeNumber } from '../../utils/numbers';

const Sales = ({ match, history }) => {
  const [loadingRequest, setLoadingRequest] = useState(false);

  function handleSubmit(values) {
    setLoadingRequest(true);

    const cellphone_client = values.cellphone_client !== null ? values.cellphone_client : ''

    fetch(requestAPI(`/sales`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${getToken()}`,
      },
      body: JSON.stringify({
        ...values,
        sales_price: normalizeNumber(values.sales_price),
        paid_commission: normalizeNumber(values.paid_commission),
        commission_net_value: normalizeNumber(values.commission_net_value),
        deal_id: values.deal_id === "" ? null : values.deal_id,
        cellphone_client,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setLoadingRequest(false);
        if (resp.error) message.error(resp.error);
        else {
          message.success('Cadastrado com sucesso!');
          history.push('/sales');
        }
      })
      .catch(() => {
        setLoadingRequest(false);
        message.error('Não foi possível fazer o cadastro');
      });
  }

  return <Fields handleSubmit={handleSubmit} loadingRequest={loadingRequest} />;
};

export default Sales;
