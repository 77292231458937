export function serialize(obj) {
  let item;
  let queryString = '';
  for (item in obj) {
    if (obj[item]) {
      queryString = queryString + `&${item}=${obj[item]}`;
    }
  }

  return queryString;
}

export function setOrder(sortOrder, setSort, fieldName) {
  if (sortOrder === 'ascend') setSort(`&sort_by=${fieldName}.ASC`);
  else if (sortOrder === 'descend') setSort(`&sort_by=${fieldName}.DESC`);
}
