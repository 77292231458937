import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Card, Table } from "antd";

import Filters from "../../components/pages/sales/filters";
import { isAdmin } from "../../services/auth";
import { downloadFile } from "../../utils/download";
import pagination from "../../utils/pagination";
import { setOrder } from "../../utils/filters";
import { formatToBRLCurrency } from "../../utils/numbers";
import { requestAPI } from "../../config";

function columns(setSort) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "id"),
    },
    {
      title: "Corretor",
      dataIndex: "broker_owner",
      key: "broker",
      render: (broker_owner) => broker_owner && broker_owner.first_name,
      sorter: (_, __, sortOrder) =>
        setOrder(sortOrder, setSort, "broker_owner.first_name"),
    },
    {
      title: "Indicador",
      dataIndex: "indicator_owner",
      key: "indicator",
      render: (indicator_owner) =>
        indicator_owner && indicator_owner.first_name,
      sorter: (_, __, sortOrder) =>
        setOrder(sortOrder, setSort, "indicator_owner.first_name"),
    },
    {
      title: "Incorporadora",
      dataIndex: "developer_owner",
      key: "developer",
      render: (developer_owner) => developer_owner && developer_owner.name,
      sorter: (_, __, sortOrder) =>
        setOrder(sortOrder, setSort, "developer_owner.name"),
    },
    {
      title: "Produto",
      dataIndex: "product_owner",
      key: "product",
      render: (product_owner) => product_owner && product_owner.name,
      sorter: (_, __, sortOrder) =>
        setOrder(sortOrder, setSort, "product_owner.name"),
    },
    {
      title: "Unidade",
      dataIndex: "product_characteristics",
      key: "product_characteristics",
      render: (product_characteristics) =>
        product_characteristics && product_characteristics.unit,
      sorter: (_, __, sortOrder) =>
        setOrder(sortOrder, setSort, "product_characteristics.unit"),
    },
    {
      title: "Cliente",
      dataIndex: "name_client",
      key: "client",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "name_client"),
    },
    {
      title: "Valor Venda",
      dataIndex: "sales_price",
      key: "sales_price",
      render: (text) => text,
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "sales_price"),
    },
    {
      title: "Data Assinatura",
      dataIndex: "signature_date",
      key: "signature_date",
      render: (signature_date) => moment(signature_date).format("DD/MM/YYYY"),
      sorter: (_, __, sortOrder) =>
        setOrder(sortOrder, setSort, "signature_date"),
    },
    {
      title: "Ações",
      key: "action",
      render: function ActionRender(_, record) {
        return (
          <span>
            <Link
              to={`/vendas/editar/${record.id}`}
              style={{ marginRight: 16 }}
            >
              Visualizar
            </Link>
          </span>
        );
      },
    },
  ];
}

const Sales = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState("");

  const [loading, setLoading] = useState({ export: false, table: false });
  const [sales, setSales] = useState({});

  function handleExport() {
    setLoading({ export: true });
    fetch(requestAPI(`/sales?limit=${pageSize}&${filters}`))
      .then((res) => res.json())
      .then(({ sales }) => {
        const csv =
          sales &&
          sales.map((item) => {
            let fieldName;
            let csvLine = "";

            for (fieldName in item) {
              switch (fieldName) {
                case "id":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "document_client":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "broker_owner":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName].first_name}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "indicator_owner":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName].first_name}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "developer_owner":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName].name}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "product_owner":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName].name}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "name_client":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "sales_price":
                  if (item[fieldName])
                    csvLine =
                      csvLine + `"${formatToBRLCurrency(item[fieldName])}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "tax":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "signature_date":
                  if (item[fieldName])
                    csvLine =
                      csvLine +
                      `"${moment(item[fieldName]).format("DD/MM/YYYY")}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "email_client":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "gerente_ibroker":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "product_characteristics":
                  if (item[fieldName]) {
                    csvLine = csvLine + `"${item[fieldName].unit}",`;
                    csvLine = csvLine + `"${item[fieldName].parking_slot}",`;
                    csvLine = csvLine + `"${item[fieldName].bedrooms}",`;
                    csvLine = csvLine + `"${item[fieldName].bathrooms}",`;
                    csvLine = csvLine + `"${item[fieldName].living_area}",`;
                    csvLine = csvLine + `"${item[fieldName].suites}",`;
                  } else csvLine = csvLine + `"",`;
                  break;
                case "paid_commission":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "commission_net_value":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "installment":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                case "status":
                  if (item[fieldName])
                    csvLine = csvLine + `"${item[fieldName]}",`;
                  else csvLine = csvLine + `"",`;
                  break;
                default:
                  break;
              }
            }

            setLoading({ export: false });

            return `${csvLine}`;
          });

        csv.unshift([
          "ID da Venda",
          "Gerente",
          "CPF",
          "Email",
          "Cliente",
          "Valor da Venda",
          "Aliquota",
          "Data da assinatura",
          "Comissão Paga",
          "Comissão Líquida",
          "Parcelas",
          "Status da Venda",
          "Nome do Produto",
          "Unidades",
          "Qtd Garagem",
          "Qtd Quartos",
          "Qtd Banheiros",
          "Área Útil",
          "Qtd Suítes",
          "Incorporadora",
          "Corretor",
          "Indicador",
        ]);

        downloadFile("Vendas.csv", csv.join("\n"));
      });
  }

  useEffect(() => {
    setLoading({ table: true });
    fetch(requestAPI(`/sales?page=${page}&limit=${pageSize}${filters}${sort}`))
      .then((res) => res.json())
      .then((response) => {
        setSales(response);
        setLoading({ table: false });
      });
  }, [filters, page, pageSize, sort]);

  return (
    <Card title="Vendas">
      <Filters setFilters={setFilters} setPage={setPage} />
      <Table
        columns={columns(setSort)}
        dataSource={sales.sales}
        footer={() => {
          return (
            isAdmin() && (
              <Button loading={loading.export} onClick={handleExport}>
                Exportar
              </Button>
            )
          );
        }}
        pagination={pagination(sales.count, setPageSize, pageSize, page)}
        loading={loading.table}
        onChange={(info) => setPage(info.current)}
        style={{ overflow: "hidden", overflowX: "scroll" }}
      />
    </Card>
  );
};

export default Sales;
