import React from 'react';
import { Menu, Layout } from 'antd';

import { Link } from 'react-router-dom';
import { logout, isAdmin } from '../../services/auth';
// 
const Header = () => {
  const { Header } = Layout;

  return (
    <Header style={{ backgroundColor: '#fff' }}>
      <Menu mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item style={{ backgroundColor: '#fff' }}>
          <Link to="/">
            <img
              src="/logo3x.png"
              alt="Logo da empresa"
              style={{ width: '160px' }}
            />
          </Link>
        </Menu.Item>
        <Menu.SubMenu title="Vendas">
          <Menu.Item key="setting:0">
            <Link to="/">Consultar Vendas</Link>
          </Menu.Item>
          <Menu.Item key="setting:1">
            <Link to="/vendas/cadastrar">Cadastrar Vendas</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu title="Incorporadoras">
          <Menu.Item key="setting:2">
            <Link to="/incorporadoras">Consultar</Link>
          </Menu.Item>
          <Menu.Item key="setting:3">
            <Link to="/incorporadoras/cadastrar">Cadastrar</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu title="Corretores">
          <Menu.Item key="setting:4">
            <Link to="/corretor">Consultar</Link>
          </Menu.Item>
          <Menu.Item key="setting:5">
            <Link to="/corretor/cadastrar">Cadastrar</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu title="Produtos">
          <Menu.Item key="setting:6">
            <Link to="/produtos">Consultar</Link>
          </Menu.Item>
          <Menu.Item key="setting:7">
            <Link to="/produtos/cadastrar">Cadastrar</Link>
          </Menu.Item>
        </Menu.SubMenu>
        {isAdmin() && (
          <Menu.SubMenu title="Usuários">
            <Menu.Item key="setting:8">
              <Link to="/usuarios">Consultar</Link>
            </Menu.Item>
            <Menu.Item key="setting:9">
              <Link to="/usuarios/cadastrar">Cadastrar</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        <Menu.Item key="perfil">
          <Link to="/perfil">Perfil</Link>
        </Menu.Item>
        <Menu.Item style={{ float: 'right' }}>
          <Link to="/" onClick={() => logout()}>
            Sair
          </Link>
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default Header;
