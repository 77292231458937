import React from 'react';
import { Card, Input, Row, Col, Form, Button, message } from 'antd';
import { getLoggedUser, getToken } from '../../services/auth';
import { requestAPI } from '../../config';

const Profile = () => {
  const { form } = Form.useForm();
  const { Item } = Form;

  function handleUpdate(values) {
    fetch(requestAPI(`/users`), {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.error) message.error(resp.error);
        else message.success('Informações alteradas com sucesso');
      })
      .catch(message.error);
  }

  return (
    <Form form={form} onFinish={handleUpdate} initialValues={getLoggedUser()}>
      <Card title="Perfil do usuário">
        <Row>
          <Col span={7}>
            <Item label="Nome" name="name" rules={[{ required: true }]}>
              <Input placeholder="nome" />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item label="Email" name="email" rules={[{ required: true }]}>
              <Input placeholder="email" />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Item label="Senha antiga" name="oldPassword">
              <Input.Password placeholder="senha" />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item label="Nova senha" name="password">
              <Input.Password placeholder="senha" />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item label="Confime a senha" name="confirmPassword">
              <Input.Password placeholder="senha" />
            </Item>
          </Col>
        </Row>

        <div className="call-to-action">
          <Button className="save" type="primary" htmlType="submit">
            Salvar
          </Button>
        </div>
      </Card>
    </Form>
  );
};

export default Profile;
