import React from 'react';

import { Card, Row, Col, Input, Button, Form, Popconfirm } from 'antd';
import { isAdmin } from '../../../services/auth';

export default ({ broker, handleSubmit, handleDelete }) => {
  const { Item } = Form;

  return (
    <Form onFinish={handleSubmit} initialValues={broker}>
      <Card title="Cadastro de Corretor">
        <Row>
          <Col span={6}>
            <Item label="Nome" name="first_name" rules={[{ required: true }]}>
              <Input placeholder="Primeiro nome" />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item
              label="Sobrenome"
              name="last_name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Sobrenome" />
            </Item>
          </Col>
          <Col span={6} offset={1}>
            <Item label="Apelido" name="nickname" rules={[{ required: true }]}>
              <Input placeholder="Apelido" />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Item
              label="Telefone"
              name="telephone"
              rules={[{ required: true }]}
            >
              <Input placeholder="Telefone" />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item
              label="Email"
              name="email"
              rules={[{ required: true, type: 'email' }]}
            >
              <Input placeholder="Email" />
            </Item>
          </Col>
          <Col span={6} offset={1}>
            <Item label="Creci" name="creci" rules={[{ required: true }]}>
              <Input placeholder="Creci" />
            </Item>
          </Col>
        </Row>

        <div className="call-to-action">
          <Button className="save" type="primary" htmlType="submit">
            Salvar
          </Button>

          {handleDelete && isAdmin() && (
            <Popconfirm
              title="Você tem certeza que deseja excluir essa incorporadora?"
              onConfirm={handleDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button className="delete" type="danger">
                Excluir
              </Button>
            </Popconfirm>
          )}
        </div>
      </Card>
    </Form>
  );
};
