import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Row, Col, Form } from 'antd';

import DropdownDevelopers from '../../Dropdowns/developers';
import DropdownBrokers from '../../Dropdowns/brokers';
import DropdownProducts from '../../Dropdowns/products';

import moment from 'moment';

import { serialize } from '../../../utils/filters';

const Filters = ({ setFilters, setPage }) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  function onSubmit(values) {
    if (values.date_from) {
      values.date_from =
        moment(values.date_from).format('YYYY-MM-DD') + ' 00:00:00';
    }
    if (values.date_to) {
      values.date_to =
        moment(values.date_to).format('YYYY-MM-DD') + ' 23:59:59';
    }

    setPage(1);
    setFilters(serialize(values));
  }

  return (
    <Form onFinish={onSubmit} form={form}>
      <Card
        title="Filtros"
        style={{ marginBottom: 16 }}
        extra={
          <Link className="ant-btn" to="/vendas/cadastrar">
            Cadastrar
          </Link>
        }
      >
        <Row>
          <Col span={7}>
            <Item name="developer" label="Incorporadora">
              <DropdownDevelopers setValue={form.setFieldsValue} />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item name="product_id" label="Produto">
              <DropdownProducts setValue={form.setFieldsValue} />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item name="broker_id" label="Corretor">
              <DropdownBrokers setValue={form.setFieldsValue} />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Item name="broker_id_indicator" label="Indicador">
              <DropdownBrokers
                placeholder="Indicador"
                setValue={form.setFieldsValue}
              />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item name="date_from" label="Data de:">
              <DatePicker
                className="item"
                placeholder="Data Assinatura"
                format="DD/MM/YYYY"
              />
            </Item>
          </Col>
          <Col span={7} offset={1}>
            <Item name="date_to" label="Data até:">
              <DatePicker
                className="item"
                placeholder="Data Assinatura"
                format="DD/MM/YYYY"
              />
            </Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          Filtrar
        </Button>
      </Card>
    </Form>
  );
};

export default Filters;
