import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';

import { getToken } from '../../services/auth';
import { requestAPI } from '../../config';

const BrokersDropdown = ({ setValue, placeholder, fieldName, value }) => {
  const [options, setOptions] = useState();
  const [inputText, setInputText] = useState(value);

  useEffect(() => {
    const queryString =
      typeof inputText === 'number'
        ? `/${inputText}`
        : `?first_name=${inputText}`;

    fetch(requestAPI(`/broker${inputText ? queryString : ''}`), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        const brokersOptions =
          resp.brokers &&
          resp.brokers.constructor === Array &&
          resp.brokers.map((broker) => ({
            value: broker.id,
            label: `${broker.first_name} - ${broker.nickname}`,
          }));

        if (!brokersOptions) setInputText(resp.brokers.first_name);

        setOptions(brokersOptions);
      });
  }, [inputText, value]);

  return (
    <AutoComplete
      allowClear
      placeholder={placeholder || 'Corretores'}
      options={options}
      onChange={(value) => {
        setInputText(value || '');
        if (!value) {
          const fieldValue = {};
          fieldValue[fieldName || 'broker_id'] = undefined;
          setValue(fieldValue);
        }
      }}
      value={inputText}
      onSelect={(value, option) => {
        const fieldValue = {};
        fieldValue[fieldName || 'broker_id'] = value;
        setValue && setValue(fieldValue);
        setInputText(option.label);
      }}
    />
  );
};

export default BrokersDropdown;
