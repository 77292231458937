import React, { useState, useEffect } from 'react';
import { message } from 'antd';

import Fields from '../../components/pages/developers/fields';

import { getToken } from '../../services/auth';
import { requestAPI } from '../../config';

const Update = (props) => {
  const [developer, setDeveloper] = useState();
  const { id } = props.match.params;

  useEffect(() => {
    fetch(requestAPI(`/developers/${id}`), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.error) {
          message.error(json.error);
        }

        setDeveloper(json);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, [id]);

  function handleSubmit(values) {
    fetch(requestAPI(`/developers/${id}`), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${getToken()}`,
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.error) {
          message.error(resp.error);
        }
        if (resp.success) {
          message.success('Alterações salvas com sucesso!');
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  function handleDelete() {
    fetch(requestAPI(`/developers/${id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `bearer ${getToken()}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.error) {
          message.error('Não foi possível excluir');
        }
        if (resp.success) {
          message.success('Excluido com sucesso!');
          props.history.push('/incorporadoras')
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  return developer ? (
    <Fields
      developer={developer}
      handleDelete={handleDelete}
      handleSubmit={handleSubmit}
    />
  ) : null;
};

export default Update;
