import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Row, Col, Input, Form, Card, Table, Button } from 'antd';

import { getToken, isAdmin } from "../../services/auth";
import { serialize, setOrder } from "../../utils/filters";
import { downloadFile } from "../../utils/download";
import pagination from "../../utils/pagination";
import { requestAPI } from "../../config";

function columns(setSort) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "id"),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "name"),
    },
    {
      title: "Logradouro",
      dataIndex: "logradouro",
      key: "logradouro",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "logradouro"),
    },
    {
      title: "Bairro",
      dataIndex: "bairro",
      key: "bairro",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "bairro"),
    },
    {
      title: "Cidade",
      dataIndex: "cidade",
      key: "cidade",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "cidade"),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "estado"),
    },
    {
      title: "Data de Entrega",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (text) => text && moment(text).format("DD/MM/YYYY"),
      sorter: (_, __, sortOrder) =>
        setOrder(sortOrder, setSort, "delivery_date"),
    },
    {
      title: "Ações",
      key: "action",
      render: (texto, record) => (
        <span>
          <Link
            to={`/produtos/editar/${record.id}`}
            style={{ marginRight: 16 }}
          >
            Visualizar
          </Link>
        </span>
      ),
    },
  ];
}
function Products() {
  const [products, setProducts] = useState({
    products: undefined,
    count_products: undefined,
  });

  const [filters, setFilters] = useState("");
  const [sort, setSort] = useState("");
  const [pageSize, setPageSize] = useState(100);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState({ export: false, table: false });

  const { Item } = Form;
  const [form] = Form.useForm();

  function handleExport() {
    setLoading({ export: true });
    fetch(requestAPI(`/product?limit=${pageSize}${filters}${sort}`), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then(({ products }) => {
        const csv =
          products &&
          products.map((item) => {
            let fieldName;
            let csvLine = "";

            for (fieldName in item) {
              switch (fieldName) {
                case "id":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "name":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "logradouro":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "numero":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "bairro":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "cidade":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "estado":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "cep":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "delivery_date":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                default:
                  break;
              }
            }

            return `${csvLine}`;
          });

        if (csv) {
          csv.unshift([
            '"ID da Produto"',
            '"Nome"',
            '"Logradouro"',
            '"Numero"',
            '"Bairro"',
            '"Cidade"',
            '"Estado"',
            '"CEP"',
            '"delivery_date"',
          ]);

          downloadFile("Vendas.csv", csv.join("\n"));
        }

        setLoading({ export: false });
      });
  }

  function onSubmit(values) {
    setFilters(serialize(values));
    setPage(1);
  }

  useEffect(() => {
    setLoading({ table: true });

    fetch(
      requestAPI(`/product?page=${page}&limit=${pageSize}${filters}${sort}`),
      {
        method: "GET",
        headers: { Authorization: `bearer ${getToken()}` },
      }
    )
      .then((resp) => resp.json())
      .then((response) => {
        setProducts(response);
        setLoading({ table: false });
      });
  }, [filters, page, pageSize, sort]);

  return (
    <>
      <Card
        title="Produtos"
        style={{ marginBottom: "1em" }}
        extra={
          <Link className="ant-btn" to="/produtos/cadastrar">
            Cadastrar
          </Link>
        }
      >
        <Form onFinish={onSubmit} form={form}>
          <Row>
            <Col span={7}>
              <Item name="name" label="Nome">
                <Input className="item" placeholder="Nome" />
              </Item>
            </Col>

            <Col span={7} offset={1}>
              <Item name="cidade" label="Cidade">
                <Input className="item" placeholder="Cidade" />
              </Item>
            </Col>

            <Col span={7} offset={1}>
              <Item name="estado" label="Estado">
                <Input className="item" placeholder="Estado" />
              </Item>
            </Col>
          </Row>

          <Row>
            <Col span={7}>
              <Item name="bairro" label="Bairro">
                <Input className="item" placeholder="Bairro" />
              </Item>
            </Col>
            <Col span={7} offset={1}>
              <Item name="cep" label="CEP">
                <Input className="item" placeholder="CEP" />
              </Item>
            </Col>
          </Row>

          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
        </Form>
      </Card>
      <Card>
        <Table
          columns={columns(setSort)}
          dataSource={products.products}
          footer={() => {
            return (
              isAdmin() && (
                <Button loading={loading.export} onClick={handleExport}>
                  Exportar
                </Button>
              )
            );
          }}
          pagination={pagination(
            products.count_products,
            setPageSize,
            pageSize,
            page
          )}
          loading={loading.table}
          onChange={(info) => setPage(info.current)}
        />
      </Card>
    </>
  );
}

export default Products;
