/**
 * Locales the value to the right BRL currency
 * @param {number} value
 * @returns {number}
 */
export function formatToBRLCurrency(value = 0) {
  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });
}

export function normalizeNumber(value) {
  if (typeof value === 'number') {
    return value;
  }

  return parseFloat(
    value &&
      value
        .replace("R$ ", "")
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".")
  ).toFixed(2);
}
