import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Input, Form, Card, Table, Button, message } from 'antd';

import { getToken, isAdmin } from "../../services/auth";
import { requestAPI } from "../../config";
import { serialize, setOrder } from "../../utils/filters";
import { downloadFile } from "../../utils/download";
import pagination from "../../utils/pagination";

function columns(setSort) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "id"),
    },
    {
      title: "Nome",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "first_name"),
    },
    {
      title: "Apelido",
      dataIndex: "nickname",
      key: "nickname",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "nickname"),
    },
    {
      title: "Creci",
      dataIndex: "creci",
      key: "creci",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "creci"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "email"),
    },
    {
      title: "Telefone",
      dataIndex: "telephone",
      key: "telephone",
      sorter: (_, __, sortOrder) => setOrder(sortOrder, setSort, "telephone"),
    },
    {
      title: "Ações",
      key: "action",
      render: (text, record) => (
        <span>
          <Link
            to={`/corretor/editar/${record.id}`}
            style={{ marginRight: 16 }}
          >
            Visualizar
          </Link>
        </span>
      ),
    },
  ];
}

function Brokers() {
  const [brokers, setBrokers] = useState({});
  const [filters, setFilters] = useState("");
  const [sort, setSort] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState({ export: false, table: false });

  const { Item } = Form;
  const [form] = Form.useForm();

  function handleExport() {
    setLoading({ export: true });
    fetch(requestAPI(`/broker?limit=${pageSize}${filters}${sort}`), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then(({ brokers }) => {
        const csv =
          brokers &&
          brokers.map((item) => {
            let fieldName;
            let csvLine = "";

            for (fieldName in item) {
              switch (fieldName) {
                case "id":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "first_name":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "nickname":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "creci":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "email":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                case "telephone":
                  csvLine = csvLine + `"${item[fieldName]}",`;
                  break;
                default:
                  break;
              }
            }

            return `${csvLine}`;
          });

        if (csv) {
          csv.unshift([
            '"ID da Corretor"',
            '"Primeiro nome"',
            '"Apelido"',
            '"CRECI"',
            '"Email"',
            '"Telefone"',
          ]);

          downloadFile("Vendas.csv", csv.join("\n"));
        }

        setLoading({ export: false });
      });
  }

  function onSubmit(values) {
    setPage(1);
    setFilters(serialize(values));
  }

  useEffect(() => {
    setLoading({ table: true });

    fetch(
      requestAPI(`/broker?page=${page}&limit=${pageSize}${filters}${sort}`),
      {
        method: "GET",
        headers: { Authorization: `bearer ${getToken()}` },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setBrokers(response);
        setLoading({ table: false });
      })
      .catch((err) => message.error(err.message));
  }, [filters, page, pageSize, sort]);

  return (
    <>
      <Card
        title="Corretores"
        style={{ marginBottom: "1em" }}
        extra={
          <Link className="ant-btn" to="/corretor/cadastrar">
            Cadastrar
          </Link>
        }
      >
        <Form onFinish={onSubmit} form={form}>
          <Row>
            <Col span={5}>
              <Item name="first_name" label="Nome">
                <Input className="item" placeholder="Nome" />
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item name="nickname" label="Apelido">
                <Input className="item" placeholder="Apelido" />
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item name="creci" label="CRECI">
                <Input className="item" placeholder="CRECI" />
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item name="email" label="Email">
                <Input className="item" placeholder="Email" />
              </Item>
            </Col>
          </Row>

          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
        </Form>
      </Card>

      <Card>
        <Table
          columns={columns(setSort)}
          dataSource={brokers.brokers}
          footer={() => {
            return (
              isAdmin() && (
                <Button loading={loading.export} onClick={handleExport}>
                  Exportar
                </Button>
              )
            );
          }}
          loading={loading.table}
          pagination={pagination(brokers.count, setPageSize, pageSize, page)}
          onChange={(info) => setPage(info.current)}
        />
      </Card>
    </>
  );
}

export default Brokers;
