import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';

import { getToken } from '../../services/auth';
import { requestAPI } from '../../config';

const ProductDropdown = ({ setValue, value, developerId }) => {
  const [options, setOptions] = useState();
  const [inputText, setInputText] = useState(value);

  useEffect(() => {
    const search =
      typeof inputText === "number"
        ? `/${inputText}`
        : `?name=${inputText}&developer=${developerId}`;
    const queryString = inputText ? search : `?developer=${developerId ?? ""}`;

    if (developerId) {
      fetch(requestAPI(`/product${queryString}`), {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          const productsOptions =
            resp.products &&
            resp.products.map((product) => ({
              value: product.id,
              label: product.name,
            }));

          if (!productsOptions) setInputText(resp.name);

          if (productsOptions) {
            setOptions(productsOptions);
          }
        });
    }
  }, [inputText, value, developerId]);

  return (
    <AutoComplete
      allowClear
      placeholder="Produtos"
      options={options}
      onChange={(value) => {
        setInputText(value || "");
        if (!value) setValue({ product_id: undefined });
      }}
      value={inputText}
      onSelect={(value, option) => {
        setValue && setValue({ product_id: value });
        setInputText(option.label);
      }}
    />
  );
};

export default ProductDropdown;