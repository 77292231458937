import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import Developers from '../pages/developers';
import DevelopersRegister from '../pages/developers/register';
import DevelopersUpdate from '../pages/developers/update';

import Sales from '../pages/sales';
import SalesRegister from '../pages/sales/register';
import SalesUpdate from '../pages/sales/update';

import Brokers from '../pages/brokers';
import BrokersRegister from '../pages/brokers/register';
import BrokersUpdate from '../pages/brokers/update';

import Products from '../pages/product';
import ProductsRegister from '../pages/product/register';
import ProductsUpdate from '../pages/product/update';

import Users from '../pages/users';
import UsersRegister from '../pages/users/register';
import UsersUpdate from '../pages/users/update';

import Profile from '../pages/users/profile';

import Login from '../pages/login';

import { isAuthenticated, isAdmin, getToken } from '../services/auth';
import Header from '../components/header';
import { requestAPI } from '../config';

const { Content } = Layout;

const PrivateRoutes = ({ component: Component, ...restProps }) => {
  const [authenticated, setAuthenticated] = useState();

  if (isAuthenticated()) {
    //Makes a request to the backend just to check if the token is valid
    fetch(requestAPI('/product'), {
      headers: {
        Authorization: `bearer ${getToken()}`,
      },
    })
      .then((response) => {
        const isTokenValid = response && response.ok;

        if (restProps.path.match('usuarios')) {
          setAuthenticated(isAdmin() && isTokenValid);
        } else {
          setAuthenticated(isTokenValid);
        }
      })
      //TODO: Alert when there is a error
      .catch((error) => console.log(error));
  } else {
    if (authenticated !== false) setAuthenticated(false);
  }

  return typeof authenticated !== 'undefined' ? (
    <Route
      {...restProps}
      render={(props) =>
        authenticated ? (
          <>
            <Header />
            <Content className="content">
              <Component {...props} />
            </Content>
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  ) : null;
};

const Routes = () => {
  return (
    <Switch>
      <PrivateRoutes path="/vendas/cadastrar" component={SalesRegister} />
      <PrivateRoutes path="/vendas/editar/:id" component={SalesUpdate} />
      <PrivateRoutes
        exact
        path="/incorporadoras/cadastrar"
        component={DevelopersRegister}
      />
      <PrivateRoutes
        exact
        path="/incorporadoras/editar/:id"
        component={DevelopersUpdate}
      />
      <PrivateRoutes exact path="/incorporadoras" component={Developers} />

      <PrivateRoutes path="/corretor/cadastrar" component={BrokersRegister} />
      <PrivateRoutes path="/corretor/editar/:id" component={BrokersUpdate} />
      <PrivateRoutes path="/corretor" component={Brokers} />

      <PrivateRoutes path="/produtos/cadastrar" component={ProductsRegister} />
      <PrivateRoutes path="/produtos/editar/:id" component={ProductsUpdate} />
      <PrivateRoutes path="/produtos" component={Products} />

      <PrivateRoutes path="/usuarios/cadastrar" component={UsersRegister} />
      <PrivateRoutes path="/usuarios/editar/:id" component={UsersUpdate} />
      <PrivateRoutes path="/usuarios" component={Users} />

      <PrivateRoutes path="/perfil" component={Profile} />

      <Route exact path="/login" component={Login} />

      <PrivateRoutes path="/" component={Sales} />
      <Route path="*" component={() => <div>Página não encontrada</div>} />
    </Switch>
  );
};

export default Routes;
